import moment from "moment";
const nationalInsuranceUpdateTime = moment("2025-04-01");

export const Config = {
  platformName: process.env.PLATFORM_NAME,
  themeName: process.env.THEME_NAME,

  apiUrl: process.env.API_URL,
  googleApiKey: process.env.GOOGLE_API_KEY,
  pricingPageUrl: process.env.PRICING_PAGE_URL,
  hirerAppUrl: process.env.HIRER_APP_URL,

  defaultShiftType: process.env.DEFAULT_SHIFT_TYPE || 'shift',
  holidayPayRate: parseFloat(process.env.HOLIDAY_PAY_RATE),
  nationalInsuranceRate: parseFloat(process.env.NATIONAL_INSURANCE_RATE),
  updatedNationalInsuranceRate: parseFloat(process.env.UPDATED_NATIONAL_INSURANCE_RATE),
  feeRate: parseFloat("0.1"),
  vatRate: parseFloat(process.env.VAT_RATE),

  minimumWage:[
    {
      rate: 6.45,
      minimumAge:18,
      period:{
        startAt:"2020-04-01",
        endAt:"2021-03-31"
      }
    },
    {
      rate: 8.20,
      minimumAge:21,
      period:{
        startAt:"2020-04-01",
        endAt:"2021-03-31"
      }
    },
    {
      rate: 8.72,
      minimumAge:25,
      period:{
        startAt:"2020-04-01",
        endAt:"2021-03-31"
      }
    },
    {
      rate: 6.56,
      minimumAge:18,
      period:{
        startAt:"2021-04-01",
        endAt:"2022-03-31"
      }
    },
    {
      rate: 8.36,
      minimumAge:21,
      period:{
        startAt:"2021-04-01",
        endAt:"2022-03-31"
      }
    },
    {
      rate: 8.91,
      minimumAge:23,
      period:{
        startAt:"2021-04-01",
        endAt:"2022-03-31"
      }
    },
    {
      rate: 6.83,
      minimumAge:18,
      period:{
        startAt:"2022-04-01",
        endAt:"2023-03-31"
      }
    },
    {
      rate: 9.18,
      minimumAge:21,
      period:{
        startAt:"2022-04-01",
        endAt:"2023-03-31"
      }
    },
    {
      rate: 9.50,
      minimumAge:23,
      period:{
        startAt:"2022-04-01",
        endAt:"2023-03-31"
      }
    },
    {
      rate: 7.49,
      minimumAge:18,
      period:{
        startAt:"2023-04-01",
        endAt:"2024-03-31"
      }
    },
    {
      rate: 10.18,
      minimumAge:21,
      period:{
        startAt:"2023-04-01",
        endAt:"2024-03-31"
      }
    },
    {
      rate: 10.42,
      minimumAge:23,
      period:{
        startAt:"2023-04-01",
        endAt:"2024-03-31"
      }
    },
    {
      rate: 8.60,
      minimumAge:18,
      period:{
        startAt:"2024-04-01",
        endAt:"2025-03-31"
      }
    },
    {
      rate: 11.44,
      minimumAge:21,
      period:{
        startAt:"2024-04-01",
        endAt:"2025-03-31"
      }
    },
    {
      rate: 11.44,
      minimumAge:23,
      period:{
        startAt:"2024-04-01",
        endAt:"2025-03-31"
      }
    },
    {
      rate: 10.00,
      minimumAge:18,
      period:{
        startAt:"2025-04-01",
        endAt:"2026-03-31"
      }
    },
    {
      rate: 12.21,
      minimumAge:21,
      period:{
        startAt:"2025-04-01",
        endAt:"2026-03-31"
      }
    },
    {
      rate: 12.21,
      minimumAge:23,
      period:{
        startAt:"2025-04-01",
        endAt:"2026-03-31"
      }
    },
  ],

  getNationalInsuranceRate() {
    if (moment().isSameOrAfter(nationalInsuranceUpdateTime)) {
      return Config.updatedNationalInsuranceRate;
    } else {
      return Config.nationalInsuranceRate;
    }
  }
}
